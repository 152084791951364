import React, { useState } from 'react'
import '../layouts/layouts.css'
import { useTransition, animated } from "react-spring"
import Form from '../components/Form'

const Contact = ({ ...location }) => {

    const [isContactVisible, setIsContactVisible] = useState(true);

    const transition = useTransition(isContactVisible, {
      from: { x: 0, y: 40, opacity: 0 },
      enter: { x: 0, y: 0, opacity: 1 },
      leave: { x: 0, y: 100, opacity: 0 },
      exitBeforeEnter: true,
    });


  return (
    <div>
      <div>{transition((style, item) =>
        item ? <animated.div style={style}>
          <div className='section-box'>
            <Form />
          </div>
          </animated.div> : ''
        )}</div>
    </div>
  )
}

export default Contact