import React from 'react'
import {Helmet} from "react-helmet";
import './form.modules.css'

const Form = () => {
  return (
    <div>
        <Helmet>
            <title>Contacto | Pablo Humanes: Estrategia, Diseño y Arquitecura</title>
            <script type="text/javascript">{`
                /** This section is only needed once per page if manually copying **/
                if (typeof MauticSDKLoaded == 'undefined') {
                    var MauticSDKLoaded = true;
                    var head            = document.getElementsByTagName('head')[0];
                    var script          = document.createElement('script');
                    script.type         = 'text/javascript';
                    script.src          = 'https://mautic.pablohumanes.es/index.php/media/js/mautic-form.js?v61fff1fa';
                    script.onload       = function() {
                        MauticSDK.onLoad();
                    };
                    head.appendChild(script);
                    var MauticDomain = 'https://mautic.pablohumanes.es/index.php';
                    var MauticLang   = {
                        'submittingMessage': "Enviando..."
                    }
                }else if (typeof MauticSDK != 'undefined') {
                    MauticSDK.onLoad();
                }`}
            </script>
            <script>{`
            var _paq = window._paq = window._paq || [];
            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
                var u="//pablohumanes.es/matomo/";
                _paq.push(['setTrackerUrl', u+'matomo.php']);
                _paq.push(['setSiteId', '1']);
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
            })();`}
            </script>
        </Helmet>

        <div id="mauticform_wrapper_contacto" className='mauticform_wrapper'>
            <form autoComplete="false" method="post" action="https://mautic.pablohumanes.es/form/submit?formId=1" id="mauticform_contacto" data-mautic-form="contacto" encType="multipart/form-data">
                <div className="mauticform-error" id="mauticform_contacto_error"></div>
                <div className="mauticform-message" id="mauticform_contacto_message"></div>
                <div className="mauticform-innerform">
                    
                    <div className="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

                    <div id="mauticform_contacto_nombre" className="mauticform-row mauticform-text mauticform-field-1">
                        <label id="mauticform_label_contacto_nombre" for="mauticform_input_contacto_nombre" className="mauticform-label">Nombre
                        <input id="mauticform_input_contacto_nombre" name="mauticform[nombre]" placeholder="Tu nombre" className="mauticform-input" type="text" />
                        <span className="mauticform-errormsg" 
                        style={{display: "none"}}
                        ></span>
                        </label>
                    </div>

                    <div id="mauticform_contacto_email" data-validate="email" data-validation-type="email" className="mauticform-row mauticform-email mauticform-field-2 mauticform-required">
                        <label id="mauticform_label_contacto_email" for="mauticform_input_contacto_email" className="mauticform-label">Tu email</label>
                        <input id="mauticform_input_contacto_email" name="mauticform[email]" placeholder="usuarix@dominio.com" className="mauticform-input" type="email" />
                        <span className="mauticform-errormsg" 
                        style={{display: "none"}}
                        >Tienes que completar este campo correctamente para poder continuar.</span>
                    </div>

                    <div id="mauticform_contacto_mensaje" className="mauticform-row mauticform-text mauticform-field-3">
                        <label id="mauticform_label_contacto_mensaje" for="mauticform_input_contacto_mensaje" className="mauticform-label">Mensaje
                        <textarea id="mauticform_input_contacto_mensaje" name="mauticform[mensaje]" placeholder='Escribe aquí tu mensaje...' className="mauticform-textarea"></textarea>
                        <span className="mauticform-errormsg" style={{display: 'none'}}
                        ></span>
                        </label>
                    </div>

                    <div id="mauticform_contacto_eres_humanx"  data-validate="eres_humanx" data-validation-type="captcha" 
                        className="mauticform-row mauticform-text mauticform-field-4 mauticform-required">
                        <label id="mauticform_label_contacto_eres_humanx" for="mauticform_input_contacto_eres_humanx" className="mauticform-label">¿Cuánto son 10 más 3?</label>
                        <span className="mauticform-helpmessage">Por favor, confirma que eres una persona.</span>
                        <input id="mauticform_input_contacto_eres_humanx" name="mauticform[eres_humanx]" placeholder="Escribe aquí la respuesta correcta con números." className="mauticform-input" type="text"  />
                        <span className="mauticform-errormsg" style={{display: 'none'}}
                        >Tienes que completar este campo correctamente para poder continuar.</span>
                    </div>

                    <div id="mauticform_contacto_submit" class="mauticform-row mauticform-button-wrapper mauticform-field-5">
                        <button type="submit" name="mauticform[submit]" id="mauticform_input_contacto_submit" value="" className="mauticform-button button-primary btn btn-default">Enviar</button>
                    </div>

                    </div>
                </div>

                <input type="hidden" name="mauticform[formId]" id="mauticform_contacto_id" value="1" />
                <input type="hidden" name="mauticform[return]" id="mauticform_contacto_return" value="" />
                <input type="hidden" name="mauticform[formName]" id="mauticform_contacto_name" value="contacto" />

            </form>
            </div>


    </div>
  )
}

export default Form